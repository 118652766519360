// *****************************************
// GENERAL VARIABLES
$item__global: #206feb;
$item__info: #2968c0;
$item__warning: #fed03d;
$item__error: #f94d4f;
$item__success: #38ac68;
//
$item__info__background: #e6f7ff;
$item__warning__background: #fffbe6;
$item__error__background: #fef2f0;
$item__success__background: #f6ffed;
//
$item__inactive__dark: rgb(102, 102, 102);
$item__inactive__light: #f0f2f5;
$item__inactive__blue: #394a5c;
//
$item__icon__1: #818181;
$item__title: #575757;
$item__title__2: #42adce;

// *****************************************
// THEME
$item__background__light: #f0f2f5;
$item__background__dark: #2a2a2a;
$item__background__blue: #16314d;

$item__theme__icon__light: #818181;
$item__theme__icon__dark: #616161;
$item__theme__icon__blue: #969696;
// *****************************************
// BORDER
$item__border__dark: #3e3e3e;
$item__border__light: gray;
$item__border__blue: #15314e;

// *****************************************
// MONITORING VIEW
$item__monitoring__card__dark: #202020;
$item__monitoring__floor__dark: #050505;
$item__monitoring__floorDots__dark: #94979b;

$item__monitoring__card__light: #ffffff;
$item__monitoring__floor__light: #e2e3e4;
$item__monitoring__floorDots__light: #94979b;

$item__monitoring__card__blue: #16314d;
$item__monitoring__floor__blue: #11273f;
$item__monitoring__floorDots__blue: #67798c;
